/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import { Link } from 'gatsby';
import DataContext from '../context/data-context';
import GatsbyLink from './gatsby-link';
// import PropTypes from 'prop-types';

const SubNavigation = () => {
  const {
    settings: { dictionary, navigation },
  } = useContext(DataContext);
  const [expanded, setExpanded] = useState(null);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setExpanded(null);
  });

  return (
    <nav className="sub-navigation outer-padding">
      <ul ref={ref}>
        <li>
          <Link to="/" className="title">
            <span>{dictionary.headerDocsShort}</span>
          </Link>
        </li>
        {navigation.map((item, index) => (
          <li
            key={item.name}
            onClick={() => {
              if (expanded !== index) {
                setExpanded(index);
              } else {
                setExpanded(null);
              }
            }}
          >
            {item.href && !item.items ? (
              <GatsbyLink to={item.href}>
                <span>{item.name}</span>
              </GatsbyLink>
            ) : (
              <button
                type="button"
                className={expanded === index ? 'expanded' : ''}
              >
                <span>{item.name}</span>
                {expanded === index ? (
                  <i className="icon icon-chevron-up" />
                ) : (
                  <i className="icon icon-chevron-down" />
                )}
              </button>
            )}
            {expanded === index && item.items && (
              <ul className="dropdown">
                {item.items.map((subitem) => (
                  <li key={subitem.name}>
                    <GatsbyLink to={subitem.href}>{subitem.name}</GatsbyLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
SubNavigation.propTypes = {};
SubNavigation.defaultProps = {};

export default SubNavigation;
