import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const GatsbyLink = ({ to, children, ...props }) => {
  if (!to) {
    console.log('/// FEJL', to, children);
    return null;
  }
  if (to.startsWith('/')) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <a href={to} {...props}>
      {children}
    </a>
  );
};

GatsbyLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
};
GatsbyLink.defaultProps = {};

export default GatsbyLink;
