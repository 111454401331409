import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import DataContext from '../context/data-context';

const MetaHeader = () => {
  const { path, settings } = useContext(DataContext);
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:image" content="/og-image-logo.png" />
      <meta
        property="og:url"
        content={`https://docs.continia.com/${settings?.prefix}${path}`}
      />

      <meta property="og:type" content="website" />
      <meta property="version" content={process.env.GATSBY_VERSION} />
    </Helmet>
  );
};

MetaHeader.propTypes = {};
MetaHeader.defaultProps = {};

export default MetaHeader;
