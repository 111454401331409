import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import algoliasearch from 'algoliasearch/lite';
import { navigate } from 'gatsby';
import DataContext from '../context/data-context';

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_CLIENT_KEY,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);
const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX);

const NavigationFilter = ({ product }) => {
  const {
    settings: { dictionary, prefix },
  } = useContext(DataContext);
  const [filterValue, setFilterValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    index
      .search(value, {
        filters: `${
          product !== 'all' ? `product:"${product}" AND ` : ''
        }language:"${prefix}"`,
        facetingAfterDistinct: true,
      })
      .then(({ hits }) => {
        // console.log(hits);
        // console.log('hits', hits);
        setSuggestions(hits);
      })
      .catch((err) => {
        console.log('///SEARCH ERROR', err);
        setSuggestions([]);
      });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } // eslint-disable-line
  ) => {
    navigate(suggestion.url.substr(6));
    setFilterValue('');
  };

  return (
    <div className="combobox">
      <i className="icon icon-filter" />
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={(item) => item.title}
        renderSuggestion={(item) => (
          <div className="suggestion">
            <div className="title">{item.title}</div>
            <div className="bc">
              <ul>
                {item.breadcrumb &&
                  item.breadcrumb.map((bc) => (
                    <li key={bc.url.substr(6)}>{bc.title}</li>
                  ))}
              </ul>
            </div>
          </div>
        )}
        inputProps={{
          placeholder: dictionary.filterPlaceholder,
          value: filterValue,
          className: 'input autocomplete is-small has-icon-left',
          onChange: (event, { newValue }) => {
            setFilterValue(newValue);
          },
          role: 'combobox',
          'aria-autocomplete': 'list',
          autoCapitalize: 'off',
          autoComplete: 'off',
          autoCorrect: 'off',
          spellCheck: 'false',
          'aria-expanded': 'false',
          'aria-activedescendant': '',
        }}
      />
    </div>
  );
};

NavigationFilter.propTypes = {
  product: PropTypes.string.isRequired,
};
NavigationFilter.defaultProps = {};

export default NavigationFilter;
