import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import LayoutContext from '../context/layout-context';
import Feedback from './feedback';
import DataContext from '../context/data-context';

const isInViewport = function (elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

const RightSidebar = ({ sections, location }) => {
  const {
    settings: { dictionary },
  } = useContext(DataContext);

  const { mainTop, mainBottom } = useContext(LayoutContext);
  const [currentHeaderId, setCurrentHeaderId] = useState(null);
  const [ref, { width }] = useMeasure();

  useEffect(() => {
    if (location.hash && !location.hash.includes('=')) {
      const elem = document.querySelector(location.hash);
      if (elem) {
        setTimeout(() => {
          window.scrollTo(0, elem.offsetTop);
          setCurrentHeaderId(location.hash.substr(1));
        }, 1000);
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const handleScroll = () => {
      const mainContent = document.getElementById('content');
      const headerElements = mainContent.querySelectorAll('h2, h3, h4, h5');
      const headersArray = Array.from(headerElements);

      const headers = headersArray
        .map((elem) => ({
          id: elem.id,
          isVisible: isInViewport(elem),
        }))
        .filter((item) => !!item.id);

      const firstVisibleIndex = headers.findIndex((item) => item.isVisible);

      // if (firstVisibleIndex > 0) {
      //   firstVisibleIndex -= 1;
      // }
      if (
        headers[firstVisibleIndex] &&
        headers[firstVisibleIndex].id !== currentHeaderId
      ) {
        setCurrentHeaderId(headers[firstVisibleIndex].id);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);    // eslint-disable-line

  return (
    <div className={`right-sidebar ${mainTop === null ? 'invisible' : ''}`}>
      <div className="right-sidebar-inner" ref={ref}>
        <div
          className="fixed-content"
          id="right-sidebar-fixed"
          style={{
            width,
            top: mainTop + 24,
            bottom: mainBottom + 24,
          }}
        >
          <Feedback />

          {sections && sections.length > 0 && (
            <nav className="doc-outlines">
              <h3>{dictionary.sectionsHeadline}</h3>
              <ol>
                {sections.map((li) => (
                  <li
                    key={li.href}
                    className={
                      li.href === `#${currentHeaderId}` ? 'active' : ''
                    }
                  >
                    <a
                      href={li.href}
                      onClick={(e) => {
                        e.preventDefault();
                        const elem = document.querySelector(li.href);
                        if (elem) {
                          window.history.pushState({}, '', li.href);
                          window.scrollTo(0, elem.offsetTop);
                        }
                      }}
                    >
                      {li.label}
                    </a>
                  </li>
                ))}
              </ol>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

RightSidebar.propTypes = {
  sections: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};
RightSidebar.defaultProps = {};

export default RightSidebar;
