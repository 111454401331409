/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

const React = require('react');
const ArticleLayout = require('./src/layouts/article-layout').default;
const DataContext = require('./src/context/data-context').default;
const { LANGUAGES } = require('./src/constants');

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onPreRouteUpdate = ({ prevLocation }) => {
  // // Only of page change set cookie consent
  if (prevLocation) {
    window.localStorage.setItem('cookieconsent', true);
  }
};

exports.onRouteUpdate = (_, pluginOptions) => {
  if (
    process.env.NODE_ENV === `production` ||
    pluginOptions.includeInDevelopment
  ) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      const data = pluginOptions.dataLayerName
        ? window[pluginOptions.dataLayerName]
        : window.dataLayer;
      const eventName = pluginOptions.routeChangeEventName
        ? pluginOptions.routeChangeEventName
        : `gatsby-route-change`;

      data.push({ event: eventName });
    }, 50);
  }
};

exports.wrapPageElement = ({ element, props }) => {
  const defaultTheme = window.localStorage.getItem('continia.theme');
  const { pageContext, location } = props;
  const { settings, toc, sections, breadcrumb = [], product } = pageContext;

  const path = location.pathname;

  const { prefix } = settings;
  const usePath = path.startsWith(`/${prefix}`)
    ? path.substr(`/${prefix}`.length)
    : path;

  let pageType = 'article';

  if (
    usePath === '/' ||
    usePath === '' ||
    LANGUAGES.find((l) => l.path === path || `${l.path}/` === path)
  ) {
    pageType = 'frontpage';
  }

  if (usePath === '/cookie-policy') {
    pageType = 'cookie-policy';
  }

  if (usePath === '/search' || usePath === '/search/') {
    pageType = 'search';
  }

  if (usePath.startsWith('/404-')) {
    pageType = '404';
  }

  const fullHtml = element?.props?.data?.markdownRemark?.html;
  const greySection = (fullHtml || '').split('<p>[GREY-SECTION]</p>')[1];

  let tocData = toc;

  if (toc) {
    if (typeof toc === 'string') {
      tocData = JSON.parse(toc);
    }
  } else {
    tocData = [];
  }

  return (
    <DataContext.Provider
      value={{
        // Need default settings for client /* paths
        settings: settings || {
          logolink: '/',
          toplinks: [],
          footerlinks: [],
          navigation: [],
          notifications: {},
        },
        breadcrumb,
        product,
        toc: tocData,
        path: usePath,
        location,
        pageType,
      }}
    >
      <ArticleLayout
        {...props}
        location={location}
        path={path}
        sections={sections}
        defaultTheme={defaultTheme || 'light'}
        pageType={pageType}
        greySection={greySection}
      >
        {element}
      </ArticleLayout>
    </DataContext.Provider>
  );
};

// props provide same data to Layout as Page element will get
// including location, data, etc - you don't need to pass it
