/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import Searchbar from './searchbar';
import DataContext from '../context/data-context';
import GatsbyLink from './gatsby-link';
import PropTypes from 'prop-types';

const Header = ({ theme }) => {
  const [showNavigation, setShowNavigation] = useState(false);
  const {
    settings: { toplinks, navigation, dictionary, logolink },
  } = useContext(DataContext);
  const [mobileSubItems, setMobileSubItems] = useState(null);
  const [mobileHeader, setMobileHeader] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <header
      role="banner"
      itemScope="itemscope"
      itemType="http://schema.org/Organization"
      className="outer-padding"
      id="header"
    >
      <a
        itemProp="url"
        href={logolink}
        aria-label="Continia Docs"
        className="logo"
      >
        <div
          className="nav-bar-logo"
          role="presentation"
          aria-hidden="true"
          itemProp="logo"
          itemScope="itemscope"
        >
          <svg
            className={theme}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="65.08 74.83 685.4 100.83"
          >
            <path
              className="st0"
              d="M236.18,112.68c-17.37,0-19.06,10.45-19.06,24.57s1.7,24.85,19.06,24.85h28.95v12.71h-33.04c-25.42,0-32.06-14.97-32.06-37.56s6.63-37.28,32.06-37.28h31.34v12.71h-27.25Z"
            ></path>
            <path
              className="st0"
              d="M356.67,137.25c0,29.94-12.99,38.41-39.82,38.41s-39.82-8.47-39.82-38.41,12.99-38.12,39.82-38.12,39.82,8.47,39.82,38.12Zm-39.82,25.7c16.95,0,22.74-7.35,22.74-25.7s-5.79-25.42-22.74-25.42-22.73,7.06-22.73,25.42,5.79,25.7,22.73,25.7Z"
            ></path>
            <path
              className="st0"
              d="M372.39,112.68c0-8.47,4.24-12.71,12.71-12.71h26.26c16.95,0,29.09,5.79,29.09,25.56v49.28h-16.52v-44.9c0-11.3-4.37-17.22-15.67-17.22h-15.11c-2.83,0-4.24,1.41-4.24,4.23v57.9h-16.52v-62.13Z"
            ></path>
            <path
              className="st0"
              d="M494.02,174.81c-16.95,0-27.54-6.07-27.54-24.43v-37.7h-10.31v-12.71h10.31v-24.01h16.52v24.01h21.32v12.71h-21.32v34.31c0,11.3,5.93,15.11,17.23,15.11h4.09v12.71h-10.31Z"
            ></path>
            <path
              className="st0"
              d="M522.33,90.09h0c0-8.42,6.83-15.25,15.25-15.25h1.27s0,15.25,0,15.25h-16.52Zm0,9.88h16.52v74.84h-16.52V99.97Z"
            ></path>
            <path
              className="st0"
              d="M558.92,112.68c0-8.47,4.24-12.71,12.71-12.71h26.27c16.95,0,29.09,5.79,29.09,25.56v49.28h-16.52v-44.9c0-11.3-4.37-17.22-15.67-17.22h-15.11c-2.83,0-4.24,1.41-4.24,4.23v57.9h-16.52v-62.13Z"
            ></path>
            <path
              className="st0"
              d="M647.09,90.09v-15.25h16.52c0,8.42-6.83,15.25-15.25,15.25h-1.27Zm0,9.88h16.52v74.84h-16.52V99.97Z"
            ></path>
            <path
              className="st0"
              d="M729.43,99.97c14.12,0,21.04,9.32,21.04,22.03v40.1c0,8.47-4.24,12.71-12.71,12.71h-37.7c-12.71,0-18.64-7.77-18.64-23.3,0-14.12,5.93-23.3,18.64-23.3h33.89v-4.8c0-7.06-3.39-10.73-10.45-10.73h-36.57v-12.71h42.5Zm-23.02,40.95c-5.65,0-7.9,3.53-7.9,10.59s2.26,10.59,7.9,10.59h23.3c2.83,0,4.24-1.41,4.24-4.23v-16.95h-27.54Z"
            ></path>
            <path
              className="st0"
              d="M118.68,125.39v49.47h-53.59c0-27.32,22.15-49.47,49.47-49.47h4.12Z"
            ></path>
            <path
              className="st0"
              d="M118.68,125.39v-49.47h53.59c0,27.32-22.15,49.47-49.47,49.47h-4.12Z"
            ></path>
          </svg>
        </div>
      </a>
      <nav className="nav-bar" role="navigation" aria-label="Global">
        <ul>
          <li className="has-spacing">
            <Link className="title" itemProp="url" to="/">
              <span>Docs</span>
            </Link>
          </li>
          {toplinks.map((link) => (
            <li key={link.href}>
              <a href={link.href}>
                <span>{link.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <span className="nav-bar-spacer is-hidden-mobile" />
      <Searchbar compact className="is-hidden-mobile" />
      <button
        type="button"
        className="burger"
        data-toggle="collapse"
        onClick={() => setShowNavigation((val) => !val)}
      >
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </button>
      {showNavigation && (
        <div className="mobile-drawer">
          <div className="inner">
            <div className="mobile-header">
              {showSearch ? (
                <>
                  <Searchbar
                    onSearch={() => {
                      setShowNavigation(false);
                    }}
                  />
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => {
                      setShowSearch(false);
                    }}
                  >
                    <span>{dictionary.cancel}</span>
                  </button>
                </>
              ) : (
                <>
                  <h3>{dictionary.headerDocsLong}</h3>
                  <div className="buttons">
                    <button
                      type="button"
                      className="search"
                      onClick={() => {
                        setShowSearch(true);
                      }}
                    >
                      <i className="icon icon-zoom" />
                    </button>
                    <button
                      type="button"
                      className="close"
                      onClick={() => {
                        setShowNavigation(false);
                      }}
                    >
                      <span />
                      <span />
                    </button>
                  </div>
                </>
              )}
            </div>
            <nav className={`mobile-nav ${mobileSubItems ? 'sub' : 'root'}`}>
              <ul>
                <li>
                  {mobileHeader ? (
                    <button
                      type="button"
                      className="subheader"
                      onClick={() => {
                        setMobileHeader(null);
                        setMobileSubItems(null);
                      }}
                    >
                      <i className="icon icon-chevron-left" />
                      <span>{mobileHeader}</span>
                    </button>
                  ) : (
                    <div className="header">
                      <h2>{dictionary.headerDocsShort}</h2>
                    </div>
                  )}
                </li>
                {(mobileSubItems || navigation).map((item) => (
                  <li key={item.name}>
                    {item.href && !item.items ? (
                      <GatsbyLink to={item.href}>
                        <span>{item.name}</span>
                      </GatsbyLink>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          setMobileHeader(item.name);
                          setMobileSubItems(item.items);
                        }}
                      >
                        <span>{item.name}</span>
                        <i className="icon icon-chevron-right" />
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  theme: PropTypes.string.isRequired,
};

Header.defaultProps = {
  theme: 'light',
};

export default Header;
