import React, { useContext } from 'react';
import { Link } from 'gatsby';
import DataContext from '../context/data-context';

const Breadcrumb = () => {
  const {
    path,
    breadcrumb,
    settings: { dictionary },
  } = useContext(DataContext);

  return (
    <nav
      data-bi-name="breadcrumb"
      itemScope=""
      itemType="http://schema.org/BreadcrumbList"
      role="navigation"
      aria-label="Breadcrumb"
    >
      <ul id="page-breadcrumbs" className="breadcrumbs">
        <li
          itemProp="itemListElement"
          itemScope=""
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to="/">
            <span itemProp="name">{dictionary.breadcrumbHomeName}</span>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        {breadcrumb
          .filter((item, index) => {
            if (breadcrumb.find((b, i) => b.url === item.url && i > index)) {
              return false;
            }
            return true;
          })
          .map((item, index) => {
            if (!item.isDir) {
              return (
                <li
                  key={`file-${item.url}`}
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="http://schema.org/ListItem"
                >
                  {item.url === path ? (
                    <span itemProp="name">{item.title}</span>
                  ) : (
                    <Link itemProp="item" to={item.url}>
                      <span itemProp="name">{item.title}</span>
                    </Link>
                  )}

                  <meta itemProp="position" content={index + 2} />
                </li>
              );
            }
            if (item.isDir) {
              return (
                <li
                  key={`folder-${item.url}`}
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="http://schema.org/ListItem"
                >
                  {index === breadcrumb.length - 2 &&
                  item.firstChildUrl === breadcrumb[index + 1].url ? (
                    <span itemProp="name">{item.title}</span>
                  ) : (
                    <Link itemProp="item" to={item.firstChildUrl}>
                      <span itemProp="name">{item.title}</span>
                    </Link>
                  )}

                  <meta itemProp="position" content={index + 2} />
                </li>
              );
            }
            return null;
          })}
      </ul>
    </nav>
  );
};

Breadcrumb.propTypes = {};
Breadcrumb.defaultProps = {};

export default Breadcrumb;
