/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import DataContext from '../context/data-context';

const Feedback = () => {
  const {
    path,
    settings: { dictionary },
  } = useContext(DataContext);
  const [reaction, setReaction] = useState(null);
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Reset on path change
  useEffect(() => {
    const defaultReaction = window.localStorage.getItem(`reaction:${path}`);
    setReaction(defaultReaction || null);
    setShowForm(false);
    setSent(false);
    setMessage('');
  }, [path]);

  const onClickReaction = (val) => {
    window.localStorage.setItem(`reaction:${path}`, val);
    setReaction(val);
    setShowForm(true);
  };

  const onClickSkip = () => {
    setShowForm(false);
    setSent(true);
    sendMessageToApi(false);
  };
  const onSubmit = () => {
    setShowForm(false);
    setSent(true);
    sendMessageToApi(true);
  };

  const sendMessageToApi = async (useMessage) => {
    const payload = {
      properties: {
        approved: reaction === 'yes',
        environment: 'prod',
        language: window.navigator.language,
        ip: '',
        location: '',
        message: useMessage ? message : '',
        url: window.location.href,
      },
      type: 'object',
    };
    try {
      const { data: geoData } = await axios.get(
        'https://www.geoplugin.net/json.gp?k=xxx'
      );
      if (geoData) {
        payload.properties.ip = geoData.geoplugin_request;
        payload.properties.location = `${geoData.geoplugin_countryCode}, ${geoData.geoplugin_city}`;
      }
    } catch (error) {
      console.error('geoplugin api error', error);
    }

    try {
      const response = await axios.post(
        'https://prod-33.northeurope.logic.azure.com:443/workflows/be53d4ca9e11428e8395bc28f74a9864/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NoA-MSXr92th0LVPwmRyhWjiFYyIOz3AVVmX714zMJU',
        payload
      );
      console.log('response', response);
    } catch (error) {
      console.error('feedback api error', error);
    }
  };

  return (
    <div className="feedback">
      {sent ? (
        <div className="ratings-send-off">
          {dictionary.feedbackSendoffMessage}
        </div>
      ) : (
        <>
          <h3>{dictionary.feedbackHeadline}</h3>
          <div className="ratings">
            <button
              type="button"
              className={`thumbs-up ${reaction === 'yes' ? 'selected' : ''}`}
              onClick={() => onClickReaction('yes')}
            >
              <i className="icon icon-thumbs-up-outlined default-state" />
              <i className="icon icon-thumbs-up-filled hover-state" />
              <span>{dictionary.feedbackYes}</span>
            </button>
            <button
              type="button"
              className={`thumbs-down ${reaction === 'no' ? 'selected' : ''}`}
              onClick={() => onClickReaction('no')}
            >
              <i className="icon icon-thumbs-down-outlined default-state" />
              <i className="icon icon-thumbs-down-filled hover-state" />
              <span>{dictionary.feedbackNo}</span>
            </button>
          </div>
          {showForm && (
            <form onSubmit={onSubmit}>
              <div className="message">
                <div className="box">
                  <textarea
                    className="textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={dictionary.feedbackMessagePlaceholder}
                    maxLength={300}
                  />
                </div>
                <div className="buttons right">
                  <button
                    className="button small text link"
                    type="button"
                    onClick={onClickSkip}
                  >
                    {dictionary.feedbackSkip}
                  </button>
                  <button
                    className="button small primary"
                    type="submit"
                    disabled={!message && 'disabled'}
                  >
                    {dictionary.feedbackSubmit}
                  </button>
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
};

Feedback.propTypes = {};
Feedback.defaultProps = {};

export default Feedback;
