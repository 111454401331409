import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import DataContext from '../context/data-context';

const Searchbar = ({ compact, className, onSearch }) => {
  const {
    location,
    settings: { dictionary },
    product,
  } = useContext(DataContext);

  const searchParams = new URLSearchParams(location?.search || '');

  const q = searchParams.get('q');

  const [query, setQuery] = useState(
    (!compact && decodeURIComponent(q) && q) || ''
  );

  let searchUrl = `/search?q=${encodeURIComponent(query)}`;

  if (compact && product && product !== 'all') {
    searchUrl += `&cat=${encodeURIComponent(product)}`;
  }

  return (
    <div className={`search-form ${className} ${compact ? 'compact' : 'full'}`}>
      <div className="search-field">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch();
            navigate(searchUrl);
          }}
        >
          <input
            type="search"
            className="input"
            placeholder={dictionary.searchPlaceholder}
            autoComplete="off"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          {compact ? (
            <i className="icon icon-zoom" />
          ) : (
            <button className="button primary" type="submit" rel="nofollow">
              <span className="icon">
                <i className="icon-zoom" />
              </span>
              <span>{dictionary.searchBtn}</span>
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

Searchbar.propTypes = {
  compact: PropTypes.bool,
  className: PropTypes.string,
  onSearch: PropTypes.func,
};
Searchbar.defaultProps = {
  compact: false,
  className: '',
  onSearch: () => {},
};

export default Searchbar;
