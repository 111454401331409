// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-cookie-policy-js": () => import("./../../../src/templates/cookie-policy.js" /* webpackChunkName: "component---src-templates-cookie-policy-js" */),
  "component---src-templates-frontpage-js": () => import("./../../../src/templates/frontpage.js" /* webpackChunkName: "component---src-templates-frontpage-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

