exports.LANGUAGES = [
  {
    key: 'da-dk',
    path: '/da-dk',
    label: 'Dansk',
  },
  {
    key: 'en-us',
    path: '/en-us',
    label: 'English',
  },
  {
    key: 'de-de',
    path: '/de-de',
    label: 'Deutsch',
  },
];

exports.IS_PRODUCTION = process.env.NODE_ENV === 'production';
