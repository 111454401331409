import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { Link } from 'gatsby';
// import path from 'path';

const HTMLParser = ({
  html: rawHtml,
  // pages, fileDirectory, prefix
}) => {
  const html = rawHtml.split('<p>[GREY-SECTION]</p>')[0];
  // Transformer for React HTML Parser
  const transform = (node, index) => {
    // Eksterne links
    if (
      node.type === 'tag' &&
      node.name === 'a' &&
      node.attribs.href &&
      ((node.attribs.href || '').startsWith('http://') ||
        (node.attribs.href || '').startsWith('https://'))
    ) {
      return (
        <a
          href={node.attribs.href}
          key={index}
          {...node.attribs}
          target="_blank"
          rel="noreferrer noopener"
        >
          {node.children.map((n, i) => convertNodeToElement(n, i, transform))}
        </a>
      );
    }

    // Interne Links
    if (
      node.type === 'tag' &&
      node.name === 'a' &&
      node.attribs.href &&
      (node.attribs['aria-label'] || '').indexOf('permalink') === -1 &&
      (node.attribs.href || '').indexOf('http://') === -1 &&
      (node.attribs.href || '').indexOf('https://') === -1 &&
      (node.attribs.href || '').indexOf('mailto:') === -1 &&
      (node.attribs.href || '').indexOf('tel:') === -1 &&
      (node.attribs.href || '').indexOf('#') !== 0 &&
      !node.attribs.href.startsWith('/images/')
    ) {
      if (node.attribs && node.attribs.class) {
        node.attribs.className = node.attribs.class;
        delete node.attribs.class;
      }
      // console.log('@ intern link', node.attribs.href);

      let to = node.attribs.href.split('#')[0];
      to = (to || '').trim();
      // console.log('/to', to);
      const hash = node.attribs.href.split('#')[1];
      delete node.attribs.href;

      if (to.endsWith('.md')) {
        // throw new Error('Link til .md fil =>', to);
        console.log('FEJL: Der linkes til en .md fil => ', to);
      }
      // if (to.substr(to.length - 3).toLowerCase() === '.md') {
      //   const toFilePath = decodeURIComponent(to)
      //     .trim()
      //     .replace(`/${prefix}/`, '/');

      //   const relativePagePath = path.normalize(
      //     `${fileDirectory}/${toFilePath}`
      //   );

      //   const page = pages.find(
      //     (p) =>
      //       p.filePath.toLowerCase() === toFilePath.toLowerCase() ||
      //       p.filePath.toLowerCase() === relativePagePath.toLowerCase()
      //   );

      //   if (page) {
      //     console.log('@@ html - page found', page);

      //     to = page.path;
      //     if (node.children.length === 0) {
      //       node.children.push({
      //         data: page.title,
      //         next: null,
      //         parent: node,
      //         prev: null,
      //         type: 'text',
      //       });
      //     }
      //   } else {
      //     console.log('//Error: Page for MD link not found:', {
      //       to,
      //       relativePagePath,
      //       fileDirectory,
      //       prefix,
      //       toFilePath,
      //       toDecoded: decodeURIComponent(to),
      //       pages,
      //     });
      //     return null;
      //   }
      // }

      if (to.startsWith('/en-us/en-us/')) {
        to = to.replace('/en-us/en-us/', '/en-us/');
      }
      if (to.startsWith('/da-dk/da-dk/')) {
        to = to.replace('/da-dk/da-dk/', '/da-dk/');
      }
      if (to.startsWith('/de-de/de-de/')) {
        to = to.replace('/de-de/de-de/', '/de-de/');
      }
      if (to.startsWith('/en-us/')) {
        to = to.replace('/en-us/', '/');
      }
      if (to.startsWith('/da-dk/')) {
        to = to.replace('/da-dk/', '/');
      }
      if (to.startsWith('/de-de/')) {
        to = to.replace('/de-de/', '/');
      }

      return (
        <Link
          to={`${to}${hash ? `#${hash} ` : ''}`}
          key={index}
          {...node.attribs}
        >
          {node.children.map((n, i) => convertNodeToElement(n, i, transform))}
        </Link>
      );
    }

    // Tables
    if (node.type === 'tag' && node.name === 'table') {
      return (
        <div key={index} className="tbl table-scroll-wrapper">
          <table className="table" {...node.attribs}>
            {node.children.map((n, i) => {
              const elem = convertNodeToElement(n, i, transform);
              // Remove weird whitespace linebreaks inside table (illegal)
              if (typeof elem === 'string' && elem.trim() === '') {
                return null;
              }
              return elem;
            })}
          </table>
        </div>
      );
    }

    // Svg bugs
    if (node.type === 'tag' && node.name === 'svg') {
      const { width, height, viewbox, preserveaspectratio, ...others } =
        node.attribs;
      return (
        <svg
          key={index}
          width={width}
          height={height}
          viewBox={viewbox}
          preserveAspectRatio={preserveaspectratio}
          {...others}
        >
          {node.children.map((n, i) => {
            if (
              n.attribs['fill-rule'] &&
              n.type === 'tag' &&
              n.name === 'path'
            ) {
              const { 'fill-rule': fillRule, ...otherAttr } = n.attribs;

              return <path key={i} fillRule={fillRule} {...otherAttr} />;
            }
            return convertNodeToElement(n, i, transform);
          })}
        </svg>
      );
    }

    // Images
    // if (node.type === 'tag' && node.name === 'img') {
    //   // node.attribs.src = node.attribs.src;
    //   return <img key={index} {...node.attribs} />;
    // }
  };

  return ReactHtmlParser(html, {
    transform,
    decodeEntities: true,
  });
};

HTMLParser.propTypes = {
  html: PropTypes.string.isRequired,
  // pages: PropTypes.array.isRequired,
};
HTMLParser.defaultProps = {};

export default HTMLParser;
