/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import LayoutContext from '../context/layout-context';
import DataContext from '../context/data-context';
import { LANGUAGES } from '../constants';

const SettingsButtons = () => {
  const { theme, setTheme, setMobileLeftNavOpen } = useContext(LayoutContext);
  const {
    path,
    settings: { dictionary },
  } = useContext(DataContext);

  const [showThemes, setShowThemes] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setShowThemes(false);
    setShowLanguages(false);
  });

  const themes = [
    { key: 'light', label: dictionary.settingsThemeLight },
    { key: 'dark', label: dictionary.settingsThemeDark },
    { key: 'high-contrast', label: dictionary.settingsThemeHighContrast },
  ];

  return (
    <div className="settings-buttons test">
      <button
        type="button"
        className="button content-btn"
        onClick={() => {
          setMobileLeftNavOpen(true);
        }}
      >
        <i className="icon icon-content" />
        <span className="">{dictionary.navigationMobileContent}</span>
      </button>
      <ul ref={ref}>
        <li>
          <button
            type="button"
            className="button"
            onClick={() => {
              setShowThemes(!showThemes);
              setShowLanguages(false);
            }}
          >
            <i className="icon icon-theme" />
            <span className="is-hidden-tablet">{dictionary.settingsTheme}</span>
          </button>
          <button
            type="button"
            className="button"
            onClick={() => {
              window.print();
            }}
          >
            <i className="icon icon-print" />
            <span className="is-hidden-tablet">{dictionary.settingsPrint}</span>
          </button>
          {showThemes && (
            <div className="popup show">
              <ul>
                {themes.map((t) => (
                  <li
                    onClick={() => {
                      window.localStorage.setItem('continia.theme', t.key);
                      setTheme(t.key);
                    }}
                    key={t.key}
                    className={t.key === theme ? 'active' : ''}
                  >
                    {t.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
        <li>
          <button
            type="button"
            className="button"
            onClick={() => {
              setShowLanguages(!showLanguages);
              setShowThemes(false);
            }}
          >
            <i className="icon icon-globe" />
            <span className="is-hidden-tablet">
              {dictionary.settingsLanguage}
            </span>
          </button>
          {showLanguages && (
            <div className="popup show">
              <ul>
                {LANGUAGES.map((l) => (
                  <li
                    onClick={() => {
                      window.localStorage.setItem('currentLanguage', l.key);
                      window.location.href = l.path;
                    }}
                    key={l.key}
                    className={path.startsWith(l.path) ? 'active' : ''}
                  >
                    {l.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

SettingsButtons.propTypes = {};
SettingsButtons.defaultProps = {};

export default SettingsButtons;
