import React, { useContext } from 'react';
import DataContext from '../context/data-context';

const Footer = React.forwardRef((props, ref) => {
  const { settings } = useContext(DataContext);
  return (
    <footer ref={ref}>
      <div className="footer-inner outer-padding">
        <ul>
          {settings.footerlinks.map((link) => (
            <li key={link.href}>
              <a href={link.href}>
                <span>{link.name}</span>
              </a>
            </li>
          ))}
          <li>© {new Date().getFullYear()} Continia Software A/S</li>
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
